import { auth } from "@/firebase";
import { getDocWithDates } from "@/utils/FirestoreHelpers";
import {
  getDayOfWeek,
  getHourFromTimeString,
  getMinuteFromTimeString,
  roundToNearest15Minutes,
} from "@/utils/Helpers";
import dayjs from "dayjs";
import { doc, getFirestore, setDoc } from "firebase/firestore/lite";
import { Appointment } from "shared/types/Appointment";
import { Product } from "shared/types/Product";
import { DurationUnits, PaymentStatus } from "shared/utils/Enums";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";
import {
  createCustomer,
  getCustomerIdByEmailAndLocation,
} from "./CustomerService";
import { getEmployeeById } from "./EmployeeService";

const db = getFirestore(app);
const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Create a new appointment
 * @param {Appointment} appointment - The appointment to create
 * @return {Promise<void>}
 */
export const createAppointment = async (
  appointment: Appointment,
): Promise<void> => {
  if (!appointment.id) {
    appointment.id = uuidv4();
  }
  appointment.confirmed = null;
  appointment.paymentStatus = PaymentStatus.Unpaid;
  let customerId = await getCustomerIdByEmailAndLocation(
    appointment.customer.email,
    appointment.locationId,
  );
  if (!customerId) {
    const customer = await createCustomer(appointment.customer);
    customerId = customer.id;
  }
  const { employee, product, ...appointmentData } = appointment;
  await setDoc(doc(db, "appointments", appointment.id), {
    ...appointmentData,
    productId: product.id,
    employeeId: employee.id,
    customerId: customerId,
    created: new Date(),
    deleted: false,
  });
};

/**
 * Get an appointment by its ID
 * @param {string} appointmentId - The ID of the appointment to retrieve
 * @return {Promise<Appointment>}
 */
export const getAppointmentById = async (
  appointmentId: string,
): Promise<Appointment> => {
  const appointmentDoc = await getDocWithDates(
    doc(db, "appointments", appointmentId),
  );
  if (appointmentDoc.exists() && !appointmentDoc.data().deleted) {
    return appointmentDoc.data() as Appointment;
  } else {
    throw new Error("Appointment not found");
  }
};

/**
 * Get appointments by employee ID and optional date
 * @param {string} employeeId - The ID of the employee
 * @param {Date} [date] - The optional date to filter appointments
 * @return {Promise<Appointment[]>}
 */
export const getAppointmentsByEmployeeId = async (
  employeeId: string,
  date?: Date,
): Promise<Appointment[]> => {
  const url = new URL(`${baseUrl}/getAppointments`);
  url.searchParams.append("employeeId", employeeId);
  if (date) {
    url.searchParams.append("date", date.toISOString());
  }
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch appointments: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Appointment[];
};

/**
 * Get appointments by multiple employee IDs
 * @param {string[]} employeeIds - The IDs of the employees
 * @return {Promise<Appointment[]>}
 */
export const getAppointmentsByEmployeeIds = async (
  employeeIds: string[],
): Promise<Appointment[]> => {
  if (employeeIds.length === 0) {
    return [];
  }
  const appointmentsArray = [] as Appointment[];
  for (const employeeId of employeeIds) {
    const employeeAppointments = await getAppointmentsByEmployeeId(employeeId);
    appointmentsArray.push(...employeeAppointments);
  }
  return appointmentsArray;
};

/**
 * Update an existing appointment
 * @param {Appointment} appointment - The appointment to update
 * @return {Promise<void>}
 */
export const updateAppointment = async (
  appointment: Appointment,
): Promise<void> => {
  await setDoc(doc(db, "appointments", appointment.id), appointment);
};

/**
 * Soft delete an appointment by its ID
 * @param {string} appointmentId - The ID of the appointment to delete
 * @return {Promise<void>}
 */
export const deleteAppointmentById = async (
  appointmentId: string,
): Promise<void> => {
  const appointmentRef = doc(db, "appointments", appointmentId);
  await setDoc(appointmentRef, { deleted: true }, { merge: true });
};

/**
 * Get available appointment slots for an employee on a specific date for a given product
 * @param {string} employeeId - The ID of the employee
 * @param {Date} date - The date to check for available slots
 * @param {Product} product - The product for which the appointment is being made
 * @return {Promise<Date[]>}
 */
export const getAvailableAppointments = async (
  employeeId: string,
  date: Date,
  product: Product,
): Promise<Date[]> => {
  const employee = await getEmployeeById(employeeId);
  if (!employee) return [];

  const dayOfWeek = getDayOfWeek(date);
  const availabilities = employee.availability.filter((x) =>
    x.days.includes(dayOfWeek),
  );
  if (!availabilities.length) return [];

  const appointments = await getAppointmentsByEmployeeId(employeeId, date);
  let availableAppointments = [] as Date[];

  availabilities.forEach((availability) => {
    let start = dayjs(date)
      .hour(getHourFromTimeString(availability.startTime))
      .minute(getMinuteFromTimeString(availability.startTime));

    const end = dayjs(date)
      .hour(getHourFromTimeString(availability.endTime))
      .minute(getMinuteFromTimeString(availability.endTime));

    const earliestAppointment = dayjs().add(2, DurationUnits.Hours);
    if (start.isBefore(earliestAppointment)) {
      start = roundToNearest15Minutes(earliestAppointment);
    }

    while (
      start.add(product.duration, "minutes").isBefore(end.add(1, "minute"))
    ) {
      availableAppointments.push(start.toDate());
      start = start.add(15, "minutes");
    }
  });

  const filterAppointments = (
    appointments: Date[],
    startDate: Date,
    endDate: Date,
  ): Date[] => {
    return appointments.filter(
      (availableAppointment) =>
        availableAppointment.getTime() <= startDate.getTime() ||
        availableAppointment.getTime() >= endDate.getTime(),
    );
  };

  appointments.forEach((appointment) => {
    const appointmentStartDate = appointment.date;
    const appointmentEndDate = dayjs(appointment.date)
      .add(product.duration, "minutes")
      .add(employee.timeBetweenAppointments, "minutes")
      .toDate();
    availableAppointments = filterAppointments(
      availableAppointments,
      appointmentStartDate,
      appointmentEndDate,
    );
  });

  if (employee.timeOff) {
    employee.timeOff.forEach((timeOff) => {
      availableAppointments = filterAppointments(
        availableAppointments,
        timeOff.startDate,
        timeOff.endDate,
      );
    });
  }

  return Array.from(
    new Set(availableAppointments.map((date) => date.getTime())),
  ).map((time) => new Date(time));
};
