import Button from "@/components/ui/Button";
import { formatDuration, formatPrice } from "@/utils/Helpers";
import { Product } from "shared/types/Product";

function AppointmentBookingProduct(props: {
  product: Product;
  onSelect: (product: Product) => void;
}) {
  const { product, onSelect } = props;
  return (
    <div className="flex w-full space-x-3">
      <div className="grow">
        <div className="flex items-baseline">
          <div>{product.name}</div>
          <div className="border-b-2 border-dotted grow"></div>
        </div>
        <div className="text-gray-500">{product.description}</div>
      </div>
      <div className="flex space-x-3">
        <div className="items-baseline text-right text-nowrap">
          <div>{formatPrice(product.price, product.currency)}</div>
          <div>{formatDuration(product.duration, product.durationUnits)}</div>
        </div>
        <Button primary type="button" onClick={() => onSelect(product)}>
          Select
        </Button>
      </div>
    </div>
  );
}

export default AppointmentBookingProduct;
