import { getDocWithDates, getDocsWithDates } from "@/utils/FirestoreHelpers";
import {
  collection,
  doc,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { Employee } from "shared/types/Employee";
import { Location } from "shared/types/Location";
import { Product } from "shared/types/Product";
import { ChargeCustomers } from "shared/utils/Enums";
import { v4 as uuidv4 } from "uuid";
import { app, auth } from "../firebase";
import {
  deleteFile,
  fileExists,
  uploadFileFromDataURL,
} from "./StorageService";
import { getUserById } from "./UserService";

const db = getFirestore(app);
const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Fetch location by ID
 * @param {string} locationId - The ID of the location to fetch
 * @return {Promise<Location>} - The location data
 * @throws {Error} - If the location is not found or invalid
 */
export const getLocationById = async (
  locationId: string,
): Promise<Location> => {
  const locationRef = doc(db, "locations", locationId);
  const locationData = await getDocWithDates(locationRef);

  if (locationData.deleted) {
    throw new Error("Location not found");
  }

  // Fetch employee data for each employee in the location
  const employeePromises: Promise<Employee>[] = locationData.employees.map(
    async (employeeId: string) => {
      const employeeRef = doc(db, "employees", employeeId);
      const employeeData = await getDocWithDates(employeeRef);
      if (!employeeData.deleted) {
        return employeeData as Employee;
      }
      return null;
    },
  );

  locationData.employees = (await Promise.all(employeePromises)).filter(
    (employee) => employee !== null,
  );

  // Fetch product data for each product in the location
  const productPromises: Promise<Product>[] = locationData.products.map(
    async (productId: string) => {
      const productRef = doc(db, "products", productId);
      const productData = await getDocWithDates(productRef);
      if (!productData.deleted) {
        return productData as Product;
      }
      return null;
    },
  );

  locationData.products = (await Promise.all(productPromises)).filter(
    (product) => product !== null,
  );

  return locationData as Location;
};

/**
 * Get locations by user
 * @return {Promise<Location[]>} - The list of unique locations associated with the user
 */
export const getLocations = async (): Promise<Location[]> => {
  const url = new URL(`${baseUrl}/getLocations`);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch locations: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Location[];
};

/**
 * Create a new location
 * @param {Location} location - The location data to create
 * @return {Promise<void>}
 */
export const createLocation = async (location: Location): Promise<void> => {
  const user = await getUserById(auth?.currentUser?.uid);

  location.id = uuidv4();
  location.employees ??= [];
  location.products ??= [];
  location.admins ??= [];
  location.users ??= [];
  location.autoConfirm = false;
  location.chargeCustomers = ChargeCustomers.Automatically;
  location.stripeAccountId = user?.stripeAccountId ?? "";
  location.createdByUserId = auth?.currentUser?.uid ?? "";
  location.created = new Date();

  await setDoc(doc(db, "locations", location.id), {
    ...location,
    deleted: false,
  });

  await updateLocation(location);
};

/**
 * Update an existing location
 * @param {Location} location - The location data to update
 * @return {Promise<void>}
 */
export const updateLocation = async (location: Location): Promise<void> => {
  if (!location.id) {
    location.id = uuidv4();
  }
  const imagePath = `/locations/${location.id}/${location.id}`;
  if (location.image.startsWith("data")) {
    if (await fileExists(imagePath)) {
      await deleteFile(imagePath);
    }
    await uploadFileFromDataURL(imagePath, location.image);
  }
  location.image = imagePath;

  const locationEmployees = [];
  for (const employee of location.employees) {
    if (!employee.id) {
      employee.id = uuidv4();
    }

    const employeeImagePath = `/locations/${location.id}/${employee.id}`;
    if (employee.image.startsWith("data")) {
      if (await fileExists(employeeImagePath)) {
        await deleteFile(employeeImagePath);
      }
      await uploadFileFromDataURL(employeeImagePath, employee.image);
    }
    employee.image = employeeImagePath;

    const employeeRef = doc(db, "employees", employee.id);
    if (!employee.created) {
      employee.created = new Date();
    }
    employee.phone ??= "";
    employee.locationId = location.id;

    await setDoc(employeeRef, { ...employee, deleted: false });
    locationEmployees.push(employee.id);
  }

  const locationProducts = [];
  for (const product of location.products) {
    if (!product.id) {
      product.id = uuidv4();
    }

    const productRef = doc(db, "products", product.id);
    if (!product.created) {
      product.created = new Date();
    }
    product.locationId = location.id;

    await setDoc(productRef, { ...product, deleted: false });
    locationProducts.push(product.id);
  }

  const existingEmployees = await getDocsWithDates(
    query(collection(db, "employees"), where("locationId", "==", location.id)),
  );

  const existingProducts = await getDocsWithDates(
    query(collection(db, "products"), where("locationId", "==", location.id)),
  );

  const locationEmployeeSet = new Set(locationEmployees);
  const locationProductSet = new Set(locationProducts);

  const employeesToDelete = existingEmployees.filter(
    (emp) => !locationEmployeeSet.has(emp.id),
  );
  const productsToDelete = existingProducts.filter(
    (prod) => !locationProductSet.has(prod.id),
  );

  for (const emp of employeesToDelete) {
    await setDoc(
      doc(db, "employees", emp.id),
      { deleted: true },
      { merge: true },
    );
  }
  for (const prod of productsToDelete) {
    await setDoc(
      doc(db, "products", prod.id),
      { deleted: true },
      { merge: true },
    );
  }

  await setDoc(
    doc(db, "locations", location.id),
    {
      ...location,
      employees: locationEmployees,
      products: locationProducts,
    },
    { merge: true },
  );
};

/**
 * Soft delete a location by ID
 * @param {string} locationId - The ID of the location to delete
 * @return {Promise<void>}
 */
export const deleteLocationById = async (locationId: string): Promise<void> => {
  const locationRef = doc(db, "locations", locationId);
  await setDoc(locationRef, { deleted: true }, { merge: true });
};

/**
 * Checks if a user is an admin of a specific location.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} locationId - The ID of the location.
 * @returns {Promise<boolean>} - A promise that resolves to true if the user is an admin of the location, otherwise false.
 */
export const isAdminOfLocation = async (userId: string, locationId: string) => {
  const location = await getLocationById(locationId);
  if (location.createdByUserId === userId) {
    return true;
  } else {
    const user = await getUserById(userId);
    return (
      user?.email && location.admins.includes(user.email) && user.emailVerified
    );
  }
};
