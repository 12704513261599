import { E164Number } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { Employee } from "shared/types/Employee";
import Button from "../ui/Button";
import ModalDialog from "../ui/ModalDialog";
import PhoneNumber from "../ui/PhoneNumber";
import TextField from "../ui/TextField";
import UploadPlaceholderImage from "/images/upload-image-placeholder.jpg";

const initialEmployeeState = {
  id: "",
  image: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  locationId: "",
  availability: [],
  timeOff: [],
  timeBetweenAppointments: 15,
  active: true,
  created: new Date(),
};

interface AddEmployeeModalDialogProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onSuccess: (employee: Employee) => void;
}

const AddEmployeeModalDialog = ({
  isModalOpen,
  setIsModalOpen,
  onSuccess,
}: AddEmployeeModalDialogProps) => {
  const [noClick, setNoClick] = useState(false);
  const [employee, setEmployee] = useState<Employee>(initialEmployeeState);
  const avatarEditor = useRef<AvatarEditor>(null);

  useEffect(() => {
    setEmployee(initialEmployeeState);
  }, [isModalOpen]);

  const handleInputChange = (field: string, value: any) => {
    setEmployee((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (avatarEditor.current && employee.image) {
      const canvas = avatarEditor.current.getImageScaledToCanvas();
      employee.image = canvas.toDataURL();
    } else {
      employee.image = "";
    }
    onSuccess(employee);
    setIsModalOpen(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    handleInputChange("image", URL.createObjectURL(acceptedFiles[0]));
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-4 text-4xl text-center text-monkey-purple">
        Add Employee
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="flex items-center justify-center space-x-5">
          <Dropzone onDrop={onDrop} noKeyboard noClick={noClick}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <AvatarEditor
                  width={150}
                  height={150}
                  image={employee.image || UploadPlaceholderImage}
                  ref={avatarEditor}
                  border={25}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1}
                  rotate={0}
                  borderRadius={100}
                  onImageChange={() => setNoClick(true)}
                />
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>
        <TextField
          elementId="employee-first-name"
          labelText="First name"
          required={true}
          onInput={(value: string) => handleInputChange("firstName", value)}
          defaultValue={employee.firstName}
          autoFocus={true}
        />
        <TextField
          elementId="employee-last-name"
          labelText="Last name"
          required={true}
          onInput={(value: string) => handleInputChange("lastName", value)}
          defaultValue={employee.lastName}
        />
        <TextField
          type="email"
          elementId="employee-email-address"
          labelText="Email"
          required={true}
          onInput={(value: string) => handleInputChange("email", value)}
          defaultValue={employee.email}
        />
        <PhoneNumber
          elementId="employee-phone-number"
          labelText="Phone number"
          required={false}
          onChange={(value: E164Number | undefined) =>
            handleInputChange("phone", value as string)
          }
          value={employee.phone as E164Number}
        />
        <div className="flex justify-center space-x-4">
          <Button secondary type="button" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button primary type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default AddEmployeeModalDialog;
