import { useEffect, useState } from "react";
import { Product } from "shared/types/Product";
import { DurationUnits } from "shared/utils/Enums";
import Button from "../ui/Button";
import Label from "../ui/Label";
import ModalDialog from "../ui/ModalDialog";
import MoneyInput from "../ui/MoneyInput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import TextField from "../ui/TextField";

const LocationProductModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  product: Product | null;
  onSuccess: (product: Product) => void;
}) => {
  const { isModalOpen, setIsModalOpen, product, onSuccess } = props;
  const price = product?.price;
  const [productName, setProductName] = useState(product?.name || "");
  const [productDescription, setProductDescription] = useState(
    product?.description || "",
  );
  const [productPrice, setProductPrice] = useState(product?.price || 0);
  const [productCurrency, setProductCurrency] = useState(
    product?.currency || "usd",
  );
  const [productDuration, setProductDuration] = useState(
    product?.duration || 60,
  );
  const [productDurationUnits, setProductDurationUnits] = useState(
    product?.durationUnits || DurationUnits.Minutes,
  );

  useEffect(() => {
    setProductName(product?.name || "");
    setProductDescription(product?.description || "");
    setProductPrice(price || 0);
    setProductCurrency(product?.currency || "usd");
    setProductDuration(product?.duration || 60);
    setProductDurationUnits(product?.durationUnits || DurationUnits.Minutes);
  }, [product]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updatedProduct: Product = {
      id: product?.id || "",
      name: productName,
      description: productDescription,
      price: productPrice,
      currency: productCurrency,
      duration: productDuration,
      durationUnits: productDurationUnits,
      locationId: product?.locationId || "",
      stripeProductId: product?.stripeProductId || "",
      created: product?.created || new Date(),
    };
    onSuccess(updatedProduct);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-2 text-4xl text-center text-monkey-purple">
        Add Service
      </div>
      <form onSubmit={onSubmit}>
        <TextField
          elementId="product-name"
          labelText="Name"
          placeholder="e.g. 60 Minute Massage"
          onInput={setProductName}
          value={productName}
          required={true}
          autoFocus={true}
        />
        <TextField
          elementId="product-description"
          labelText="Description"
          placeholder="e.g. Relaxing massage with essential oils"
          onInput={setProductDescription}
          value={productDescription}
        />
        <div className="flex space-x-4">
          <div className="basis-6/12">
            <MoneyInput
              elementId="product-price"
              labelText="Price"
              amount={productPrice}
              setAmount={setProductPrice}
              currency={productCurrency}
              required={true}
            />
          </div>
          <div className="basis-6/12">
            <Label>Currency*</Label>
            <Select
              name="product-currency"
              value={productCurrency}
              defaultValue={productCurrency}
              onValueChange={setProductCurrency}
              required={true}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Currency" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"usd"}>USD</SelectItem>
                <SelectItem value={"cad"}>CAD</SelectItem>
                <SelectItem value={"eur"}>EUR</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="basis-6/12">
            <TextField
              elementId="product-duration"
              labelText="Duration"
              type="number"
              onInput={(value: string) => setProductDuration(parseFloat(value))}
              value={productDuration.toString()}
              required={true}
            />
          </div>
          <div className="basis-6/12">
            <Label>Units*</Label>
            <Select
              name="product-duration-units"
              value={productDurationUnits}
              defaultValue={productDurationUnits}
              onValueChange={(value: string) =>
                setProductDurationUnits(value as DurationUnits)
              }
              required={true}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Units" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={DurationUnits.Minutes}>Minutes</SelectItem>
                <SelectItem value={DurationUnits.Hours}>Hours</SelectItem>
                <SelectItem value={DurationUnits.Days}>Days</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-5">
          <Button secondary type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button primary type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default LocationProductModalDialog;
