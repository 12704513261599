import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationProductModalDialog from "@/components/locations/LocationProductModalDialog";
import LocationSidebar from "@/components/locations/LocationSidebar";
import Button from "@/components/ui/Button";
import { getLocationById, updateLocation } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import {
  createProduct,
  getProducts,
  updateProduct,
} from "@/services/ProductService";
import Store from "@/store";
import { formatPrice } from "@/utils/Helpers";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { Product } from "shared/types/Product";

const Products = () => {
  const { isLoading, setIsLoading } = Store();
  const { locationId } = useParams();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    const fetchData = async () => {
      const products = await getProducts(locationId!);
      setProducts(products);
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [refreshKey]);

  const addProduct = () => {
    setSelectedProduct(null);
    setIsModalOpen(true);
  };

  const editProduct = (product: Product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const onSuccess = async (product: Product) => {
    setIsLoading(true);
    product.locationId = locationId!;
    if (product.id) {
      await updateProduct(product)
        .catch((error) => logError(error))
        .finally(() => setRefreshKey((prevKey) => prevKey + 1));
    } else {
      await createProduct(product)
        .catch((error) => logError(error))
        .finally(() => setRefreshKey((prevKey) => prevKey + 1));
    }
  };

  const onDragEnd = async (result: DropResult) => {
    console.log(result);
    if (!result.destination) return;
    const reorderedProducts = Array.from(products);
    const [movedProduct] = reorderedProducts.splice(result.source.index, 1);
    reorderedProducts.splice(result.destination.index, 0, movedProduct);
    setProducts(reorderedProducts);
    try {
      await updateLocationProducts(locationId!, reorderedProducts);
    } catch (error) {
      logError(error);
    }
  };

  const updateLocationProducts = async (
    locationId: string,
    products: Product[],
  ) => {
    const location = await getLocationById(locationId);
    await updateLocation({ ...location, products });
  };

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <LocationProductModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        product={selectedProduct}
        onSuccess={(product: Product) => {
          onSuccess(product);
          setIsModalOpen(false);
        }}
      />
      <div className="text-4xl text-monkey-purple">Services</div>
      <div className="mt-4">
        <Button
          secondary
          type="button"
          onClick={addProduct}
          className="text-lg !min-w-[200px]"
        >
          Add New Service
        </Button>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="products">
            {(provided: DroppableProvided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="m-h-[200px]"
              >
                {products.map((product, i) => {
                  return (
                    <Draggable
                      key={product.id}
                      draggableId={product.id}
                      index={i}
                    >
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="w-full p-4 my-4 bg-white rounded-lg shadow-md cursor-pointer"
                          onClick={() => editProduct(product)}
                        >
                          <div className="flex items-center justify-between">
                            <div className="text-lg font-bold">
                              {product.name}
                            </div>
                            <div className="text-lg font-bold">
                              {product.price && (
                                <div>
                                  {formatPrice(product.price, product.currency)}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-sm text-gray-500">
                            {product.description}
                          </div>
                          <div>
                            {product.duration} {product.durationUnits}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </NavigationContainer>
  );
};

export default Products;
