import NewLocationDetails from "@/components/locations/NewLocationDetails";
import NewLocationEmployees from "@/components/locations/NewLocationEmployees";
import { createLocation } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import Store from "@/store";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Employee } from "shared/types/Employee";
import { Location } from "shared/types/Location";
import { Product } from "shared/types/Product";
import NavigationContainer from "../../components/layout/NavigationContainer";
import { auth } from "../../firebase";

const NewLocation = () => {
  const navigate = useNavigate();
  const { setIsLoading } = Store();
  const [step, setStep] = useState(1);
  const [newLocation, setNewLocation] = useState<Location>({
    employees: [] as Employee[],
    products: [] as Product[],
    admins: [] as string[],
    users: [] as string[],
  } as Location);

  const onBack = (location: Location) => {
    setStep(step - 1);
    setNewLocation(location);
  };

  const onContinue = (location: Location) => {
    setStep(step + 1);
    setNewLocation(location);
  };

  const onFinish = async (location: Location) => {
    setIsLoading(true);
    if (auth?.currentUser?.uid) {
      location.createdByUserId = auth.currentUser.uid;
    }
    if (
      auth?.currentUser?.email &&
      !location.admins.includes(auth.currentUser.email)
    ) {
      location.admins.push(auth.currentUser.email);
    }
    location.employees.forEach((employee) => {
      if (employee.email && !location.users.includes(employee.email)) {
        location.users.push(employee.email);
      }
    });
    await createLocation(location).catch((error) => logError(error));
    navigate("/locations");
  };

  return (
    <NavigationContainer>
      {step === 1 && (
        <NewLocationDetails location={newLocation} onContinue={onContinue} />
      )}
      {step === 2 && (
        <NewLocationEmployees
          location={newLocation}
          onBack={onBack}
          onContinue={onFinish}
        />
      )}
    </NavigationContainer>
  );
};

export default NewLocation;
