import { logError } from "@/services/LoggingService";
import { getFileURL } from "@/services/StorageService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Location } from "shared/types/Location";
import NavigationContainer from "../../components/layout/NavigationContainer"; // Assuming you have a NavigationContainer component
import AddLocationTile from "../../components/locations/AddLocationTile";
import { LocationTile } from "../../components/locations/LocationTile"; // Assuming you have a LocationTile component
import { auth } from "../../firebase";
import { getLocations } from "../../services/LocationService";
import Store from "../../store";

const Locations = () => {
  const { isLoading, setIsLoading } = Store();
  const navigate = useNavigate();
  const handleNavigate = (path: string) => () => navigate(path);
  const [listOfLocations, setListOfLocations] = useState<Location[]>([]);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    const fetchData = async () => {
      if (auth?.currentUser?.uid) {
        const locationsArray = await Promise.all(
          (await getLocations()).map(async (location) => {
            location.image = (await getFileURL(location.image)) ?? "";
            return location;
          }),
        );
        setListOfLocations(locationsArray);
      }
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [listOfLocations.length]);

  return (
    <NavigationContainer>
      <div className="flex flex-wrap justify-center">
        {listOfLocations.map((location) => (
          <LocationTile
            key={location.id}
            location={location}
            onClick={handleNavigate(`/locations/overview/${location.id}`)}
          />
        ))}
        <AddLocationTile />
      </div>
    </NavigationContainer>
  );
};

export default Locations;
